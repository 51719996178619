import {action, configure, makeObservable, observable} from 'mobx';
import {Inertia} from "@inertiajs/inertia";

export default class MapsStore {
    // Массив городов и магазинов для Yandex карты
    @observable allData = [];
    @observable data = [];
    @observable currentShops = {};
    @observable mapsValue = '';
    @observable shopsNumber = 0;
    @observable shopsElement = 0;
    @observable mapsLoader = '';
    @observable mapsType = 'start';

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }

    /**
     * Получение данных о магазинах для Yandex карты
     */
    @action getShopsData = async () => {
        // Данные для фильтрации
        let values = {
            mapsType: this.mapsType,
            mapsValue: this.mapsValue,
            shopsNumber: this.shopsNumber
        };

        // Стучимся на сервак
        const response = await axios.post('/get_shops_all', values);
        try {
            const {success} = response.data
            const {status, data} = success;

            if (status) {
                this.mapsLoader = '';
                if (this.mapsType === 'start') {
                    this.allData = data;
                }
                this.data = data;
            }

            return success
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Переключение .active
     * @returns {boolean}
     */
    @action toggleShopsTip = () => {
        try {
            const elements = document.querySelectorAll('.js-filter-shop');
            elements.forEach((item, index) => {
                item.classList.remove('active');
                if (index === this.shopsElement) {
                    item.classList.add('active');
                }
            });
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Запускаем фильтрацию по магазинам (переключалки)
     * @param element
     * @param type
     * @param typeKey
     */
    @action filterShopStart = async (element, type, typeKey) => {
        this.mapsLoader = 'load';
        this.shopsElement = element;
        this.mapsType = type
        this.shopsNumber = typeKey;
        this.toggleShopsTip();
        await this.getShopsData();
    }

    /**
     * Запускаем поиск по Yandex карте (input)
     * @param e
     */
    @action searchMaps = async (e, type) => {
        this.mapsLoader = 'load';
        this.mapsValue = e.target.value;
        this.mapsType = type;
        await this.getShopsData();
    }

    /**
     * Сбрасываем поиск
     */
    @action searchMapsBlur = () => {
        if (this.mapsValue === '') {
            this.data = this.allData;
        }
    }
}
