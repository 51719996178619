import {action, configure, makeObservable, observable} from 'mobx';
import isEmpty from "is-empty";
import {getDataProductActive, isString} from "@/Helpers/Function";


export default class SoulStore {
    // Данные по SOUL
    @observable soulData = [];
    // Данные по SOUL браслетам
    @observable soulBraceletData = [];
    // Дополнительные данные по соул браслетам
    @observable additionalDataBracelets = [];
    // Данные по размерам SOUL украшений
    @observable soulProductData = [];
    // Soul - активный элемент
    @observable soulElement = {};
    // Soul браслет - активный элемент
    @observable soulBraceletElement = {};
    // Номер активного элемента первого слайдера
    @observable indexFirstSlider = 2;
    // Номер активного элемента второго слайдера
    @observable indexSecondSlider = 2;
    // Лоадер
    @observable soulLoader = false;

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }

    /**
     * Получаем данные по соул браслетам
     * @returns {Promise<unknown>}
     */
    @action getMasterSoul = (data = {}) => {
        // Соулы
        this.soulData = data.soulData;
        // Браслеты
        this.soulBraceletData = data.soulBraceletData;
        // Первый (выбранный) соул для слайдера
        this.soulElement = this.soulData[this.indexFirstSlider];
        // Первый (выбранный) браслет для слайдера
        this.soulBraceletElement = this.soulBraceletData[this.indexSecondSlider];
        if (this.soulBraceletData.length !== 0) {
            // Размеры первого браслета
            this.soulProductData = this.soulBraceletElement.product_data;
        }
        // Активный элемент product_data
        this.rootStore.productDetailStore.productDataActive = [data.productDataActive];
        // Пишем активный размер
        this.rootStore.productDetailStore.activeSize = this.rootStore.productDetailStore.productDataActive[0].razmer;
        this.rootStore.productDetailStore.shortActiveSize = this.rootStore.productDetailStore.productDataActive[0].size;
    }

    /**
     * Выбираем соул
     * @param swiper
     */
    @action soulOnActiveIndexChange = (swiper) => {
        try {
            // Индекс слайдера
            this.indexFirstSlider = swiper.realIndex;
            // Выбираем соул
            this.soulElement = this.soulData[swiper.realIndex];
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Выбираем браслет
     * @param swiper
     */
    @action soulBraceletOnActiveIndexChange = (swiper) => {
        try {
            // Индекс слайдера
            this.indexSecondSlider = swiper.realIndex;
            // Выбираем браслет
            this.soulBraceletElement = this.soulBraceletData[swiper.realIndex];
            // Записываем размеры активного браслета
            this.soulProductData = this.soulBraceletElement.product_data;
            // Минимальный продукт по цене
            this.rootStore.productDetailStore.productDataActive = this.soulProductData;
            // Активный размер
            this.rootStore.productDetailStore.activeSize = getDataProductActive(this.soulProductData);
            // Активный короткий размер
            this.soulDataClick(this.rootStore.productDetailStore.activeSize);
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Активный короткий размер
     */
    @action soulDataClick = (size) => {
        try {
            if (!isString(size)) {
                throw new Error('This is not a string');
            }
            const firstIndex = size.indexOf('(');
            const lastIndex = size.indexOf(')');
            // Активный короткий размер
            this.rootStore.productDetailStore.shortActiveSize = size.slice(firstIndex + 1, lastIndex);
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Добавляем в корзину
     * @returns {boolean}
     */
    @action soulAddBasket = () => {
        try {
            // Скидываем выбранные элементы в модалке дополнительного выбора
            this.soulBraceletData.filter((item) => {
                return item.checked = false;
            });

            this.rootStore.modalStore.openModalAdditionalChoiceSoul();
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Дополнительный выбор браслетов
     * @param el
     */
    @action additionalChoiceSoulClick = (el) => {
        try {
            // Добавляем или удаляем
            let index = this.additionalDataBracelets.indexOf(el.id);
            if (index === -1) {
                this.additionalDataBracelets.push(el.id);
            } else {
                this.additionalDataBracelets.splice(index, 1);
            }


            // Активный элемент
            this.soulBraceletData.filter((item) => {
                if (el.id == item.id) {
                    return el.checked = !el.checked;
                }
            });
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Добавление в корзину браслетов из модалки дополнительного выбора
     * @returns {boolean}
     */
    @action additionalChoiceSoulAddBasket = () => {
        try {
            let productsSet = [], firstElementCount = 0, secondElementCount = 0;
            // Добавляем соул
            this.rootStore.basketStore.basketProducts.forEach((el) => {
                if (el.idProduct === this.soulElement.id && el.idProductData === this.soulElement.product_data[0].id) {
                    return firstElementCount = Number(el.count);
                }
            });
            productsSet.push({idProduct: this.soulElement.id, idProductData: this.soulElement.product_data[0].id, count: firstElementCount + 1});

            // Добавляем браслет
            this.rootStore.basketStore.basketProducts.forEach((el) => {
                if (el.idProduct === this.soulBraceletElement.id && el.idProductData === this.rootStore.productDetailStore.productDataActive[0].id) {
                    return secondElementCount = Number(el.count);
                }
            });
            productsSet.push({idProduct: this.soulBraceletElement.id, idProductData: this.rootStore.productDetailStore.productDataActive[0].id, count: secondElementCount + 1});

            if (this.rootStore.productDetailStore.shortActiveSize === '') {
                console.log('Не известен короткий размер соул браслета');
                return false;
            }

            if (this.additionalDataBracelets.length !== 0) {
                this.additionalDataBracelets.map((el) => {
                    // Получаем элемент
                    const newElement = this.soulBraceletData.filter((item) => {
                        return el === item.id;
                    })[0];
                    // Получаем продукт дату у элемента, что бы добавить нужное изделие в корзину
                    const productData = newElement.product_data.filter((item) => {
                        if (item.razmer === '' || item.razmer === null) {
                            return item;
                        } else {
                            return item.razmer.includes(this.rootStore.productDetailStore.shortActiveSize);
                        }
                    })[0];

                    // Добавляем доп браслет
                    let elementCount = 0;
                    this.rootStore.basketStore.basketProducts.forEach((el) => {
                        if (el.idProduct === newElement.id && el.idProductData === productData.id) {
                            return elementCount = Number(el.count);
                        }
                    });
                    productsSet.push({idProduct: newElement.id, idProductData: productData.id, count: elementCount + 1});
                });
            }

            this.rootStore.basketStore.addOrUpdateProductSet(productsSet, true);

            // Очищаем выбранные дополнительные браслеты
            this.additionalDataBracelets = [];

            // // Получаем класс всплывашки
            // const addSnackbarsStore = this.rootStore.addSnackbarsStore;
            //
            // // Передаем данные в сплывашку
            // addSnackbarsStore.data = {
            //     text: `Товары добавлены!`,
            //     autoHide: 3000,
            //     severity: 'success',
            //     basket: true
            // };
            //
            // //Открываем всплывашку
            // addSnackbarsStore.handleClickAddSnackbars();

        } catch (e) {
            console.log('e', e);
        } finally {
            // Закрываем модалку
            this.rootStore.modalStore.closeModalAdditionalChoiceSoul();
        }
    }
}
